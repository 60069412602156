import { css } from 'astroturf';
import React, { Component } from 'react';
import About from '../components/About';
import CTA from '../components/CTA';
import FeatureListPage from '../components/FeatureListPage';
import PageHeader from '../components/PageHeader';
import SEO from '../components/SEO';
import Testimonials from '../components/Testimonials';
import Layout from '../layout';

const styles = css`
  .icon {
    max-width: 100%;
    width: 10rem;
    --white: #fff;
    margin-bottom: 2rem;
  }
`;

class Page extends Component {
  render() {
    return (
      <Layout>
        <SEO title="Product tour" keywords={['']} pathname={this.props.location.pathname} />
        <PageHeader>
          <svg
            className={styles.icon}
            width="137"
            height="60"
            viewBox="0 0 137 60"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <linearGradient id="sqgradient" gradientTransform="rotate(45)">
                <stop offset="0" stopColor="rgb(44, 130, 173)" stopOpacity="1" />

                <stop offset="1" stopColor="rgb(103, 178, 111)" stopOpacity="1" />
              </linearGradient>
            </defs>
            <rect width="53" height="60" rx="8" fill="url(#sqgradient)" />
            <rect x="60" width="43" height="50" rx="8" fill="url(#sqgradient)" />
            <rect x="110" width="27" height="37" rx="8" fill="url(#sqgradient)" />
          </svg>
          <h1>The perfect browser for web developers and designers.</h1>
          <h2>Polypane cares about making your site responsive, fast and accessible.</h2>
        </PageHeader>
        <About />
        <FeatureListPage />
        <Testimonials />
        <CTA />
      </Layout>
    );
  }
}

export default Page;
