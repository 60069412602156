import { css } from 'astroturf';
import { StaticQuery, graphql } from 'gatsby';
import React from 'react';
import SmallCTA from './CTA-small-platforms';
import Feature from './Feature';
import FeatureCloud from './FeatureCloud';
import FeatureVideo from './FeatureVideo';
import Technologies from './Technologies';
import Link from './link';
import Socials from './Socials';

const styles = css`
  .featureList {
    padding: 0rem 0 5rem;
    text-align: center;

    & > h2 {
      padding: 5rem 2rem 0rem;
      font-weight: 900;
      font-size: clamp(18px, 16px + 3vw, 40px);
      letter-spacing: -0.015em;
      color: #0a1530;
      max-width: 80rem;
      margin-left: auto;
      margin-right: auto;
    }
    & > p {
      margin: 5rem auto 1rem;
      padding: 0 2rem;
      max-width: 50rem;
      font-size: calc(20px + 0.8vw);
      font-weight: 400;
      opacity: 0.6;

      &.nopad {
        margin: 0 auto;
      }
      & > small {
        font-size: calc(14px + 0.25rem);
      }
    }

    & li {
      color: #000;
    }
  }

  .primaryButton {
    pointer-events: all;
    margin: 0;
    font-size: calc(14px + 0.5vw);
    font-weight: 800;
    color: rgba(255, 255, 255, 1);
    background: #107db5;
    border: 0px solid #107db5;
    padding: 0.33em 1rem;
    border-radius: 8px;
    text-align: center;
    display: inline-block;
    text-decoration: none;
    line-height: 26px;
    transition: all 0.25s ease-in-out;
    overflow: hidden;
    white-space: nowrap;
    margin-left: -4px;

    & span {
      position: relative;
      z-index: 1;
    }

    &::before {
      content: ' ';
      display: block;
      background: linear-gradient(135deg, #4ca2cd, #67b26f);
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      opacity: 0;
      transition: opacity 0.25s ease-in-out;
      border-radius: 8px;
    }

    @media (min-width: 600px) {
      font-size: 16px;
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 2rem;
      padding-right: 2rem;
      line-height: 40px;
      height: 40px;
    }

    &:hocus {
      text-decoration: none;
      &::before {
        opacity: 1;
      }
    }
  }
`;

// zooming

function FeatureList() {
  return (
    <StaticQuery
      query={photoquery}
      render={(data) => (
        <section className={styles.featureList}>
          <FeatureVideo screenshot={data.devicewall} type="light" large brow="Multiple panes">
            <h3>Never resize your browser again</h3>

            <p>
              Emulate real devices, use your responsive CSS breakpoints or pick your own dimensions. No resizing needed.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.syncedactions} type="light" large brow="Synced panes">
            <h3>Syncs all your interactions</h3>
            <p>
              Your keyboard and mouse interactions are synced between all viewports. Scrolling, hovers, clicks and form
              input, you only need to do it once.
            </p>
          </FeatureVideo>

          {/* <FeatureVideo screenshot={data.deviceVideo} type="light" brow="Emulation">
            <h3>Device emulation, without the hassle</h3>
            <p>
              Emulate popular devices with a single click. Touch emulation, toggle network conditions, switch between
              light and dark modes on the fly and send custom headers for each pane.
            </p>
          </FeatureVideo> */}

          <Feature gatsbyImg={data.portal} type="light" full brow="Cross-browser and Cross-device">
            <h3>Other browser invited.</h3>
            <p>Share your local development environment anywhere, on any browser, on any device. </p>
            <p>
              <Link to="/portal/">Polypane Portal</Link> keeps everything in sync whether that's the scroll position,
              interactions, style edits or layout debugging.
            </p>
          </Feature>

          <FeatureVideo screenshot={data.darkmode} type="light" large brow="Emulation">
            <h3>Dark mode media feature testing</h3>
            <p>
              Develop your site in light and dark mode at the same time, both in view and synced together. There is no
              faster way to build sites with dark and light mode.
            </p>
          </FeatureVideo>

          {/* <SmallCTA /> */}

          {/* <h2>Make your browser work for you</h2> */}

          {/* <FeatureVideo screenshot={data.liveVideo} type="light" brow="Element panel &amp; Live CSS panel">
            <h3>Edit all panes at the same time</h3>
            <p>
              Make changes to all panes at once using our Elements panel and Live CSS editor. We'll automatically import
              any Google font you use for quick prototyping.
            </p>
          </FeatureVideo> */}

          {/* <FeatureVideo screenshot={data.browseVideo} type="light" brow="Browse panel">
            <h3>Documentation on the side</h3>
            <p>
              Quickly show documentation or references in the side browser, so you don't have to lose sight of your
              project. <em>That's right, there's a browser in our browser.</em>
            </p>
          </FeatureVideo> */}

          {/* <SmallCTA /> */}

          {/* <h2>Everything needed to make great web experiences.</h2> */}

          {/* <FeatureVideo screenshot={data.breakpointVideo} type="light" brow="Media features">
            <h3>
              Detect CSS breakpoints
              <br />
              Automatically
            </h3>
            <p>
              Use the CSS breakpoints in your stylesheets to automatically generate viewports for every view your site
              supports.
            </p>
          </FeatureVideo> */}

          <Feature gatsbyImg={data.devex} full type="light" brow="Chromium devtools">
            <h3>Your favorite DevTools extensions</h3>
            <p>
              Install and use your favorite devtools, with support for <strong>React DevTools</strong>,{' '}
              <strong>Vue.js DevTools</strong>, <strong>Redux DevTools</strong>, Angular, Svelte and many more.
            </p>
          </Feature>

          {/* <FeatureVideo screenshot={data.liveReloadVideo} type="light" brow="zero configuration">
            <h3>
              Intelligent Live and Hot reloading
              <br /> For everyone
            </h3>
            <p>
              Live reloading for any development environment, from plain HTML to Wordpress to React or Vue. CSS and
              images even update without a page refresh.
            </p>
          </FeatureVideo> */}

          <SmallCTA />

          <h2>Debugging an issue becomes quick and easy</h2>

          <FeatureVideo screenshot={data.debugtools} type="light" large brow="Debug tools">
            <h3>Custom debug tools for each pane</h3>
            <p>
              Over forty debug tools let you easily check contrast and accessibility, edit your page visually and get
              insights into your page structure.
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.metadata2} type="light" large brow="Meta panel">
            <h3>Social media previews and meta info</h3>
            <Socials />
            <p>
              Live previews of how your site looks when shared on social media. All your meta information is visible so
              you catch typos and missing info in a glance.
            </p>
            <p>
              <Link to="/social-media-previews/">Deep dive into our social media previews</Link>
            </p>
          </FeatureVideo>

          <FeatureVideo screenshot={data.links} type="light" large brow="Outline panel">
            <h3>Let your browser tell you which links are broken.</h3>
            <p>
              Polypane automatically checks all the links on your page and if they're broken, redirect or have referrer
              issues, we'll let you know.
            </p>
          </FeatureVideo>
{/*
          <FeatureVideo screenshot={data.referenceVideo} type="light" brow="Reference image">
            <h3>Build pixel perfect sites</h3>
            <p>
              Compare an image with the expected design to your live site with different comparison modes built right
              in.
            </p>

            <p>
              <small>* We don't think sites have to be pixel perfect. But getting close is nice.</small>
            </p>
          </FeatureVideo> */}

          <SmallCTA />

          {/* <h2>Testing takes minutes instead of days</h2> */}

          {/* <FeatureVideo screenshot={data.overlaysVideo} type="light" brow="Debug tools">
            <h3>See like your users do</h3>
            <p>
              Test your website with many different visual impairment simulators, like color blindness and cataracts but
              also situational impairments like bright sunlight or devices with night mode.
            </p>
          </FeatureVideo> */}

          {/* <FeatureVideo screenshot={data.screenshots} type="light" large brow="Screenshots">
            <h3>Full page screenshots</h3>
            <p>
              You want best in class screenshots, and we have them. No other browser comes close in terms of rendering
              accuracy. We also support screenshots of all your viewports.
            </p>
            <p>
              <Link to="/screenshot-comparison/" className={styles.primaryButton}>
                <span>Compare full page screenshots</span>
              </Link>
            </p>
          </FeatureVideo> */}

          <Technologies />

          <h2>And much more</h2>
          <FeatureCloud type="light" />
          <SmallCTA />
        </section>
      )}
    />
  );
}

export default FeatureList;

const photoquery = graphql`
  query FeaturesPageQuery {
    devicewall: file(relativePath: { eq: "videos/marketers/devicewall.mp4" }) {
      publicURL
    }
    syncedactions: file(relativePath: { eq: "videos/marketers/syncedactions.mp4" }) {
      publicURL
    }
    debugtools: file(relativePath: { eq: "videos/marketers/debugtools.mp4" }) {
      publicURL
    }
    metadata2: file(relativePath: { eq: "videos/marketers/metadata2.mp4" }) {
      publicURL
    }
    screenshots: file(relativePath: { eq: "videos/marketers/screenshot.mp4" }) {
      publicURL
    }
    darkmode: file(relativePath: { eq: "videos/marketers/darkmode.mp4" }) {
      publicURL
    }
    links: file(relativePath: { eq: "videos/marketers/links.mp4" }) {
      publicURL
    }
    liveVideo: file(relativePath: { eq: "videos/livecss.mp4" }) {
      publicURL
    }
    deviceVideo: file(relativePath: { eq: "videos/devices.mp4" }) {
      publicURL
    }
    breakpointVideo: file(relativePath: { eq: "videos/breakpoints.mp4" }) {
      publicURL
    }
    browseVideo: file(relativePath: { eq: "videos/browse.mp4" }) {
      publicURL
    }
    devtoolsVideo: file(relativePath: { eq: "videos/devtools.mp4" }) {
      publicURL
    }
    liveReloadVideo: file(relativePath: { eq: "videos/live-reload.mp4" }) {
      publicURL
    }
    handoffVideo: file(relativePath: { eq: "videos/handoff.mp4" }) {
      publicURL
    }
    zoomVideo: file(relativePath: { eq: "videos/zooming.mp4" }) {
      publicURL
    }
    modesVideo: file(relativePath: { eq: "videos/modes.mp4" }) {
      publicURL
    }
    referenceVideo: file(relativePath: { eq: "videos/reference.mp4" }) {
      publicURL
    }
    overlaysVideo: file(relativePath: { eq: "videos/overlays.mp4" }) {
      publicURL
    }
    more: file(relativePath: { eq: "images/building_website.svg" }) {
      publicURL
    }
    darklight: file(relativePath: { eq: "images/overview-split.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    devex: file(relativePath: { eq: "images/devtools-extensions.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
    portal: file(relativePath: { eq: "images/portal/feature.png" }) {
      publicURL
      childImageSharp {
        gatsbyImageData(breakpoints: [320, 375, 768, 1024, 1024], quality: 100, layout: FULL_WIDTH)
      }
    }
  }
`;
